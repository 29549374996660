.word-font {
    font-size: 22px;
    font-weight: bold;
  }

.translation-font {
  font-size: 22px;
  font-weight: bold;
}

.tag-font {
  font-size: 12px;
  font-style: italic;
  color: grey;
}

.article-font {
  font-size: 12px;
  font-style: italic;
  color: grey;
}

.page-number-font {
  font-size: 20px;
}

table {
  border: 2px solid forestgreen;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

th {
  border-bottom: 1px solid black;
}

td {
  height: 70px;
  text-align: center;
  align-items: center;
 /*  border-bottom: 1px solid grey; */
}