.App {
  width: 100vw;
  height: 100vh;
  display: flex; 
  justify-content: center;
  align-items: center;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}

.logo-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

.grid-container {
  display: grid;
  grid-template-columns: 40% 20% 40%; /* switch to better relative values in future */
  padding: 2px;
}
.grid-item {
  padding: 2px;
  text-align: center;
}

.flex-container { 
  display: flex; 
} 

.edit-fab-style {
  float: right;
}

.standard-margin {
  margin: 5px;
  padding: 5px;
}

.edit-word-margin {
  margin: 5px;
  padding: 5px;
}